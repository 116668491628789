const REACT_APP_BACKEND_API_PROTOCOL = window.REACT_APP_BACKEND_API_PROTOCOL || 'http';
const REACT_APP_BACKEND_API_URL = window.REACT_APP_BACKEND_API_URL || 'localhost';
const REACT_APP_BACKEND_API_PORT = window.REACT_APP_BACKEND_API_PORT || '7500';

const REACT_APP_KEYCLOAK_REALM = window.REACT_APP_KEYCLOAK_REALM || 'bocc';
const REACT_APP_KEYCLOAK_URL = window.REACT_APP_KEYCLOAK_URL || 'http://localhost:8080/';
const REACT_APP_KEYCLOAK_CLIENT_ID = window.REACT_APP_KEYCLOAK_CLIENT_ID || 'ai-bocc-fe';
const REACT_APP_KEYCLOAK_LOGOUT_URL = window.REACT_APP_KEYCLOAK_LOGOUT_URL || 'http://localhost:3000/';

export {  
  REACT_APP_BACKEND_API_PROTOCOL,
  REACT_APP_BACKEND_API_URL,
  REACT_APP_BACKEND_API_PORT,
  
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_LOGOUT_URL
}