import { makeStyles } from '@material-ui/styles';
import logo from '../images/ck_logo.svg';
import appName from '../images/app-name.png';
import { Button, Typography } from '@mui/material';
import getKeycloakInstance from '../helper/KeycloakInstance';

import {
  REACT_APP_KEYCLOAK_LOGOUT_URL
} from '../config.js';
import { useEffect, useState } from 'react';
import packageJson from '../../package.json';

import {
  REACT_APP_BACKEND_API_PROTOCOL,
  REACT_APP_BACKEND_API_URL,
  REACT_APP_BACKEND_API_PORT,
} from '../config.js';

const handleLogout = () => {
  const kc = getKeycloakInstance();
  kc.logout({ redirectUri: REACT_APP_KEYCLOAK_LOGOUT_URL });
};  

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    height: '100vh',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },  
  header: {
    position: 'sticky',
    top: 0,
    // color: '#AE5730',
    // backgroundColor: theme.palette palette.background.paper,
    padding: 15,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // justifyContent: 'space-between',
    textAlign: 'center',
  },  
  footer: {
    position: 'sticky',
    bottom: 0,
    color: '#AE5730',
    fontSize: '1rem',
    // backgroundColor: theme.palette.background.paper,
    // padding: theme.spacing(2),
    zIndex: 1000,
    textAlign: 'right',
    paddingBottom: 10,
  },  
  content: {
    flex: 1,
    overflowY: 'auto',
    // backgroundColor: '#ffffff',
    // padding: theme.spacing(2),
  },  
  logout: {
    // flex: 1,
    textAlign: 'right',
    paddingBottom: 10,
  },  
  logo: {
    height: '120px',
    paddingRight: '40px',
  },  
  appName: {
    height: '60px',
  },  
}));  

interface HomeProps {
  children: React.ReactNode;
  givenName: string;
}  

const Home: React.FC<HomeProps> = ({ children, givenName }) => {
  const [appVersionFE, setAppVersionFE] = useState('n.a.');
  const [appVersionBE, setAppVersionBE] = useState('n.a.');
  const classes = useStyles();

  useEffect(() => {
    async function fetchVersion() {
      try {
        const response = await fetch(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/info/app-version`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setAppVersionBE(data.appVersion);
        setAppVersionFE(packageJson.version);
      } catch (error) {
        console.error('Error sending text to API:', error);
      }  
    }

    fetchVersion();      
  }, []);

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <img src={logo} alt="Logo" className={classes.logo} />
        <img src={appName} alt="Logo" className={classes.appName} />
      </header>
      <main className={classes.logout}>
        <Button variant='outlined' onClick={handleLogout}>logout - {givenName}</Button> {/* Logout button */}
      </main>
      <main className={classes.content}>
        {children}
      </main>
      <footer className={classes.footer}>
        @2024 BoCC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Typography variant='caption' color='primary'>{appVersionFE}</Typography> <Typography variant='caption' color='danger'>|</Typography> <Typography variant='caption' color='info'>{appVersionBE}</Typography>
      </footer>
    </div>
  );
};

export default Home;