import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Tooltip } from '@mui/material';
import { BlockOutlined, FileDownloadOutlined, DeleteOutlined, TranscribeOutlined, CheckOutlined, CloseOutlined, HourglassEmptyOutlined, TranslateOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import DropArea from './DropArea';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import {
  REACT_APP_BACKEND_API_PROTOCOL,
  REACT_APP_BACKEND_API_URL,
  REACT_APP_BACKEND_API_PORT,
} from '../config.js';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface Data {
  id: number;
  file_name: string;
  transcribe_status: string;
  translations: string[];
  original_language: string;
}

const options = [
  { key: 'ar-AE', value: 'Arabo AE' },
  { key: 'ar-SA', value: 'Arabo SA' },
  { key: 'en-US', value: 'Inglese USA' },
  { key: 'en-GB', value: 'Inglese UK' },
  { key: 'it-IT', value: 'Italiano' },
  { key: 'fr-FR', value: 'Francese' },
  { key: 'es-ES', value: 'Spagnolo' },
];


const VideoTable: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | undefined>('');
  const [transcribeSelectedOption, setTranscribeSelectedOption] = useState<string | undefined>('');

  const [isTranslateModalOpen, setIsTranslateModalOpen] = useState(false);
  const [isTranscribeModalOpen, setIsTranscribeModalOpen] = useState(false);

  const [currentId, setCurrentId] = useState<number | null>(null);

  const handleTranslateClick = async (id: number) => {
    setSelectedOption('');
    console.log('Id: ', id);
    setCurrentId(id);
    setIsTranslateModalOpen(true);
  };

  const handleTranscribeApiClick = async (id: number) => {
    setTranscribeSelectedOption('');
    console.log('Id: ', id);
    setCurrentId(id);
    setIsTranscribeModalOpen(true);
  };

  const handleTranscribeApiCall = async () => {
    for (let i = 0; i <= 5; i++) {
      setTimeout(() => {
        fetchData();
      }, 500);
    }
    try {
      setIsTranscribeModalOpen(false);
      const response = await axios.post(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/transcribe/${currentId}/${transcribeSelectedOption}`);
      console.log('Transcribe response:', response.data);
      fetchData();
    } catch (error) {
      console.error('Error transcribing video:', error);
    }
  };

  const handleTranslateApiCall = async () => {
    for (let i = 0; i <= 5; i++) {
      setTimeout(() => {
        fetchData();
      }, 500);
    }
    try {
      // console.log('Id: ', currentId);
      // console.log('Language: ', selectedOption);
      setIsTranslateModalOpen(false);
      const response = await axios.post(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/text/translate/${currentId}/${selectedOption}`);
      console.log('Transcribe response:', response.data);
      fetchData();
    } catch (error) {
      console.error('Error transcribing video:', error);
    }
  };

  const handleDownloadClick = async (id: number) => {
    try {
      const response = await axios.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/get/${id}`);
      console.log('Download response:', response.data.result.transcript);
      const blob = new Blob([response.data.result.transcript], { type: 'text/plain' });
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlBlob;
      a.download = `${response.data.result.file_name}-transcript.txt`; // Adjust the filename as needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading video:', error);
    }
  };

  const handleDeleteClick = async (id: number) => {
    try {
      const response = await axios.post(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/delete/${id}`);
      console.log('Delete response:', response.data);
      fetchData();
    } catch (error) {
      console.error('Error deleting video:', error);
    }
  };

  const fetchData = async () => {
    // if (!pullData) return;
    console.log('Fetching data');
    try {
      const response = await axios.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/all`);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    // const intervalId = setInterval(fetchData, 30000);
    // return () => clearInterval(intervalId);
  }, []);

  const dowmloadLanguageTranslation = async (id: number, language: string) => {
    try {
      const response = await axios.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/get/translation/${id}/${language}`);
      console.log('Download response:', response.data.result.transcript);
      const blob = new Blob([response.data.result.translation], { type: 'text/plain' });
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlBlob;
      a.download = `${response.data.result.file_name}-transcript-${language}.txt`; // Adjust the filename as needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading video:', error);
    }
  };

  const getOptionValueByKey = (key: string) => {
    const optionObject = options.find(opt => opt.key === key);
    return optionObject ? optionObject.value : null;
  };

  return (
    <>
      <Modal open={isTranslateModalOpen} onClose={() => setIsTranslateModalOpen(false)}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Seleziona la lingua per la traduzione
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => <TextField {...params} label="Seleziona Lingua" variant="outlined" />}
              onChange={(event, newValue) => {
                setSelectedOption(newValue?.key);
                console.log(newValue);
              }}

            />
          </Typography>
          <br />
          <Button variant='contained' style={{ marginRight: '10px' }}
            disabled={selectedOption === ''}
            onClick={() => handleTranslateApiCall()}
          >
            traduci
          </Button>
          <Button variant='outlined' color='error'
            onClick={() => setIsTranslateModalOpen(false)}
          >
            annulla
          </Button>
        </Box>
      </Modal>
      <Modal open={isTranscribeModalOpen} onClose={() => setIsTranscribeModalOpen(false)}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Seleziona la lingua per la trascrizione
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => <TextField {...params} label="Seleziona Lingua" variant="outlined" />}
              onChange={(event, newValue) => {
                setTranscribeSelectedOption(newValue?.key);
                console.log(newValue);
              }}

            />
          </Typography>
          <br />
          <Button variant='contained' style={{ marginRight: '10px' }}
            disabled={transcribeSelectedOption === ''}
            onClick={() => handleTranscribeApiCall()}
          >
            trascrivi
          </Button>
          <Button variant='outlined' color='error'
            onClick={() => setIsTranscribeModalOpen(false)}
          >
            annulla
          </Button>
        </Box>
      </Modal>
      <DropArea updateVideoTable={fetchData} />
      <div style={{ height: '10px' }}> </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Nome del video</TableCell>
              <TableCell>Trascrizione</TableCell>
              <TableCell>Lingua originale</TableCell>
              <TableCell>Azioni</TableCell>
              <TableCell>Traduzioni disponibili</TableCell>
              {/* Add other headers as necessary */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.file_name}</TableCell>
                <TableCell>
                  {row.transcribe_status === 'COMPLETED' ? <span className='text-icon-center'>Disponibile&nbsp;<CheckOutlined color='primary' /></span> : <span />}
                  {row.transcribe_status === 'UPLOADED' ? <span className='text-icon-center'>Non disponibile<CloseOutlined color='secondary' /></span> : <span />}
                  {row.transcribe_status === 'TRANSCRIBING' ? <span className='text-icon-center'>Trascrizione in corso<HourglassEmptyOutlined style={{ color: 'orange' }} /></span> : <span />}
                </TableCell>
                <TableCell>
                  <Tooltip title='scarica lingua originale'>
                    <span>
                      <Button
                        disabled={row.transcribe_status === 'UPLOADED' || row.transcribe_status === 'TRANSCRIBING'}
                        onClick={() => handleDownloadClick(row.id)}
                      >
                        {row.original_language ? <span>{row.original_language}&nbsp;<FileDownloadOutlined /></span> : <BlockOutlined />}
                      </Button>
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title='Trascrivi'>
                  <span>
                  <IconButton
                      disabled={row.transcribe_status === 'COMPLETED' || row.transcribe_status === 'TRANSCRIBING'}
                      onClick={() => handleTranscribeApiClick(row.id)}
                      style={(row.transcribe_status === 'COMPLETED' || row.transcribe_status === 'TRANSCRIBING') ? {color: 'lightgray'} : { color: 'orange' } }
                    >
                      <TranscribeOutlined />
                    </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title='Traduci'>
                  <span>
                  <IconButton
                      disabled={row.transcribe_status !== 'COMPLETED'}
                      onClick={() => handleTranslateClick(row.id)}
                      color='info'
                    >
                      <TranslateOutlined />
                    </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title='Elimina video'>
                    <IconButton
                      disabled={row.transcribe_status === 'TRANSCRIBING'}
                      onClick={() => handleDeleteClick(row.id)}
                      color='error'
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {row.translations.map((translation, index) =>
                    <Tooltip title={getOptionValueByKey(translation)}>
                      <Button key={translation} size='small' onClick={() => dowmloadLanguageTranslation(row.id, translation)}>
                        {translation}
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VideoTable;