import React from 'react';
import './App.css';
import VideoTable from './components/VideoTable';
import Home from './components/Home';
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./themes/theme";
import { useEffect, useState } from 'react';
import getKeycloakInstance from './helper/KeycloakInstance';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotAuthenticated from './components/NotAuthenticated';

function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const [givenName, setGivenName] = useState('');

  useEffect(() => {
    if (isInitialized) {
      const kc = getKeycloakInstance();
      if (getKeycloakInstance().authenticated)
        setGivenName(kc.tokenParsed.given_name);
    } else {
      setGivenName('');
    }
  }, [isInitialized]); // This effect runs when `isInitialized` changes


  useEffect(() => {
    const kc = getKeycloakInstance();
    const initialize = async () => {
      try {
        await kc.init({ onLoad: 'login-required', checkLoginIframe: true }).then((authenticated: any) => {
          if (authenticated) {
            kc.onTokenExpired = () => {
              console.log('Token expired');
            };
          } else {
            console.log('User is not authenticated');
          }
        }, (error: any) => { console.error('Error initializing Keycloak:', error) }
        );
        setIsInitialized(true); // Update state to indicate completion
      } catch (error) {
        console.error('Initialization failed:', error);
      }
    };

    initialize();
  }, []); // This effect runs only once on component mount

  return (
    <ThemeProvider theme={appTheme}>
      <BrowserRouter>

        <div className="App">
          <header className="App-header">
            <Routes>
              <Route path="/" element={<Home givenName={givenName}><VideoTable /></Home>} />
              <Route path="/not-authenticated" element={<NotAuthenticated />} />
            </Routes>
          </header>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
