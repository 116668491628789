import React from 'react';
import logo from '../images/ck_logo.svg';
import { Button, Typography } from '@mui/material';
import getKeycloakInstance from '../helper/KeycloakInstance';


const NotAuthenticated: React.FC = (props) => {

  return (
    <div>
      <img src={logo} alt="logo" style={{height: '230px', paddingBottom: '30px'}}/>
      <Typography variant="h3" style={{paddingBottom: '30px'}}>Non sei autenticato</Typography>
      <Button size='large' color='warning' variant='outlined' onClick={() => {getKeycloakInstance()}}>Login</Button>
    </div>
  );
};

export default NotAuthenticated;