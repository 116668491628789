import Keycloak from 'keycloak-js';

import {
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_CLIENT_ID} from '../config.js';

let keycloakInstance;

const keycloakConfig = {
  realm: REACT_APP_KEYCLOAK_REALM,
  url: REACT_APP_KEYCLOAK_URL,
  clientId: REACT_APP_KEYCLOAK_CLIENT_ID
};

// let kc = new Keycloak(keycloakConfig);
const getKeycloakInstance = () => {
  if (!keycloakInstance) {
    keycloakInstance = new Keycloak(keycloakConfig);
    // Initialize or configure the instance if needed
  }
  return keycloakInstance;
};

export default getKeycloakInstance;